import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Plotly from 'plotly.js-basic-dist';

@Component({
    selector: 'app-show-count-plot',
    templateUrl: './show-count-plot.component.html',
    styleUrls: ['./show-count-plot.component.scss'],
    standalone: false
})

export class ShowCountPlotComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
  }

async ngOnInit() {

    document.title = `Counts : ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    this.plotParticipantCounts(window.opener['traces']);
  }

  plotParticipantCounts(traces) {
    let title = `User/Device Counts<br>Locus Id ${this.route.snapshot.queryParams['locusID']} Start Time ${this.route.snapshot.queryParams['date']}`;
    let layout = {
      title: title,
      showlegend: true,
      xaxis: {title: "Time (UTC)", autotick: true, nticks: 40},
      yaxis: {title: "Count"},
      width: 1600, height: 600,
      margin: {t:40, b:80, l:40, r:40}
    };
    let config = {
      scrollZoom: true
    };

    Plotly.newPlot("plotly-div", traces, layout, config)
  }
}
