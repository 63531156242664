import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {firstValueFrom, Observable, of, tap, throwError} from 'rxjs';
import { HttpBackend, HttpClient } from "@angular/common/http";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  public readonly FEDRAMP = 'FedRAMP';
  public readonly PRODUCTION = 'prod';
  public readonly INTEGRATION = 'int';
  public readonly DEVELOPMENT = 'dev';
  private config: EnvConfig | undefined;
  private localHttp: HttpClient;

  constructor(httpBackend: HttpBackend) {
    // Use default HttpBackend to bypass the journal-client's http interceptor when getting a local json file
    this.localHttp = new HttpClient(httpBackend);
  }

  public getRedirectAuthUrl() {
    if (this.isEnvControlledByConfig()) {
      return `${this.config.siteUrlScheme}://journaltool.${this.config.domain}`;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT) {
      return environment.devRedirectAuthUrl;
    } else if (curEnv === this.INTEGRATION) {
      return environment.intRedirectAuthUrl;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodRedirectAuthUrl;
    } else if (curEnv === this.FEDRAMP) {
      return environment.fedRampRedirectAuthUrl;
    } else {
      throwError('unrecognized domain');
    }
  }

  public getClientId() {
    if (this.isEnvControlledByConfig()) {
      return this.config.journalClientClientId;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT || curEnv === this.INTEGRATION) {
      return environment.intClientId;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodClientId;
    } else if (curEnv === this.FEDRAMP) {
      return environment.fedRampClientId;
    } else {
      throwError('unrecognized domain');
    }
  }

  public getClientSecret() {
    if (this.isEnvControlledByConfig()) {
      return this.config.journalClientClientSecret;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT || curEnv === this.INTEGRATION) {
      return environment.intClientSecret;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodClientSecret;
    } else if (curEnv === this.FEDRAMP) {
      return environment.fedRampClientSecret;
    } else {
      throwError('unrecognized domain');
    }
  }

  //I think this is only called in components that can't be accessed. Need to create seperate ticket to clean this up, but this function shouldn't be used
  public getAtlasUrl() {
    if (this.isEnvControlledByConfig()) {
      return `atlas.${this.config.domain}`;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT || curEnv === this.INTEGRATION) {
      return environment.intAtlasUrl;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodAtlasUrl;
    } else {
      throwError('unrecognized domain');
    }
  }

  public getIdentityUrl() {
    if (this.isEnvControlledByConfig()) {
      return `identity.${this.config.domain}`;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT || curEnv === this.INTEGRATION) {
      return environment.intIdentityUrl;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodIdentityUrl;
    } else if (curEnv === this.FEDRAMP) {
      return environment.fedRampIdentityUrl;
    } else {
      throwError('unrecognized domain');
    }
  }

  public getBrokerUrl() {
    if (this.isEnvControlledByConfig()) {
      return `idbroker.${this.config.domain}`;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT || curEnv === this.INTEGRATION) {
      return environment.intBrokerUrl;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodBrokerUrl;
    } else if (curEnv === this.FEDRAMP) {
      return environment.fedRampBrokerUrl;
    } else {
      throwError('unrecognized domain');
    }
  }

  public getJournalServiceUrl() {
    if (this.isEnvControlledByConfig()) {
      return `${this.config.siteUrlScheme}://journal-tool.${this.config.domain}/journal-tool`;
    }
    const curEnv = this.getCurrentEvironment();
    if (curEnv === this.DEVELOPMENT) {
      return environment.devJournalServiceUrl;
    } else if (curEnv === this.INTEGRATION) {
      return environment.intJournalServiceUrl;
    } else if (curEnv === this.PRODUCTION) {
      return environment.prodJournalServiceUrl;
    } else if (curEnv === this.FEDRAMP) {
      return environment.fedRampJournalServiceUrl;
    } else {
      throwError('unrecognized domain');
    }
  }

  public getDevJournalServiceUrl() {
    return environment.devJournalServiceUrl;
  }

  public getCurrentEvironment() {
    let domain = window.location.href as string;
    if (domain.indexOf('127.0.0.1:8000') !== -1 || domain.indexOf('localhost:8000') !== -1) {
      return this.DEVELOPMENT;
    } else if (domain.indexOf('journaltool-int.webex.com') !== -1) {
      return this.INTEGRATION;
    } else if (domain.indexOf('journaltool.webex.com') !== -1) {
      return this.PRODUCTION;
    } else if (domain.indexOf('usgov') !== -1) {
      return this.FEDRAMP;
    } else {
      console.log('unrecognized web domain');
      throwError('unrecognized web domain');
    }
  }

  public isEnvControlledByConfig(): boolean {
    return this.config !== undefined &&
      this.config.domain?.length > 0;
  }

  public getDomain(): string {
    return this.config.domain;
  }

  /**
   * notes (as of 2024-01-19):
   * - a controlled environment config file may be populated by a pipeline post-build process before deploy
   * - this process may populate config data in '.../app-config.json'
   * - to simulate how this config data is rendered for local testing, install 'gucci', then run at commandline:
   *   - see: https://github.com/noqcks/gucci
   *   ```
   *   # with a variable
   *   gucci -o missingkey=default -s domain='.webex.navy.mil' ./app-config.json.tpl > ./apps/ch-host/src/app-config.json
   *   # without a variable
   *   gucci -o missingkey=default ./app-config.json.tpl > ./apps/ch-host/src/app-config.json
   *   ```
   *
   * @returns
   */
  public loadControlledEnvConfig$(): Observable<EnvConfig | undefined> {
    return this.localHttp.get<EnvConfig | undefined>('./app-config.json').pipe(
      tap((appConfig) => {
        this.config = appConfig;
        console.log(`loaded configuration: domain:${this.config?.domain}, siteUrlScheme:${this.config?.siteUrlScheme}, journalClientClientId:${this.config?.journalClientClientId}`);
      }),
      catchError((err, caught) => {
        this.config = undefined;
        console.log(`./app-config.json not found. assuming a non-psf env`);
        return of(undefined);
      })
    );
  }

  public loadControlledEnvConfig(): Promise<EnvConfig | undefined> {
    return firstValueFrom(this.loadControlledEnvConfig$());
  }
}

// for PSF-related requirements, load config for controlled env before letting app initialize
export function envConfigAppInitializerFactory(envService: EnvironmentService) {
  return () => envService.loadControlledEnvConfig();
}

export interface EnvConfig {
  siteUrlScheme: string;
  domain: string;
  journalClientClientId: string;
  journalClientClientSecret: string;
}
