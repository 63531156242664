import {Component, Injectable, OnInit} from '@angular/core';

@Component({
    selector: 'app-jstree-spinner',
    templateUrl: './jstree-spinner.component.html',
    styleUrls: ['./jstree-spinner.component.scss'],
    standalone: false
})

@Injectable({ providedIn: 'root' })
export class JstreeSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public show() {
    document.getElementById('jstree_spinner').style.display = "block";
  }

  public hide() {
    document.getElementById('jstree_spinner').style.display = "none";
  }

}
