import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';
import {NgxJsonViewerModule} from "ngx-json-viewer";

@Component({
    selector: 'app-call-summary',
    templateUrl: './call-summary.component.html',
    styleUrls: ['./call-summary.component.scss'],
    standalone: false
})
export class CallSummaryComponent implements OnInit {

  locusId = ""
  startTime = ""

  data = null;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  jsonView: boolean = true;

  async ngOnInit() {

    document.title = `Call Summary : ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getCallSummary(this.route.snapshot.queryParams['environment'], this.route.snapshot.queryParams['locusID'], this.route.snapshot.queryParams['date']);
      this.data = request['data'];
    } else {
      this.data = window['data'];
    }
    if (this.data['callSummaries'].length > 0 && this.data['callSummaries'][0]['callParticipants'].length > 0 && this.data['callSummaries'][0]['callParticipants'][0]['callLegs'].length >0) {
        this.locusId = this.data['callSummaries'][0]['callParticipants'][0]['callLegs'][0].locusId;
        this.startTime = this.data['callSummaries'][0]['callParticipants'][0]['callLegs'][0].startTime;
    }

    this.jstreeSpinner.hide();

    UiUtils.setDefaultCursor();
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

  public expand(jsTreeId) {
    document.getElementById(jsTreeId).attributes['expanded'] = true;
  }
}
