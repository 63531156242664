import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthenticationService } from '../_services/authentication/authentication.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  responseContains = _.rest((response: HttpErrorResponse, searchStrings) => {
    const responseData = _.get(response, 'data');
    if (!responseData) {
      return false;
    }
    const responseDataString = JSON.stringify(responseData);

    return _.some(searchStrings, (searchString) => _.includes(responseDataString, searchString));
    });

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          const errResponse = {
            ...err,
            data: err.error,
          };
          if (this.isHttpAuthorizationError(errResponse)) {
            // Refresh access token due to HTTP authentication error.
            return this.refreshTokenAndRetry(req, next);
          }
          if (this.isCIInvalidAccessTokenError(errResponse)) {
            // Refresh access token due to invalid CI error.
            return this.refreshTokenAndRetry(req, next);
          }

          if (this.refreshTokenIsExpiredOrInvalid(errResponse)) {
            // Refresh-token is expired or invalid
            return of(this.authenticationService.logout(true));
          }
          return throwError(err);
        })
      );
    }

  private refreshTokenAndRetry(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return from(this.authenticationService.refreshAccessToken()).pipe(switchMap(result => next.handle(req)));
  }

  private isHttpAuthorizationError(response): boolean {
    return (
      response.status === 401 &&
      this.responseContains(
        response,
        'This request requires HTTP authentication',
        'Request unauthorized',
        '200001'
      )
    );
  }

  private isCIInvalidAccessTokenError(response) {
    return response.status === 400 && this.responseContains(response, 'Invalid access token');
  }

  private refreshTokenIsExpiredOrInvalid(response) {
    return (
      response.status === 400 &&
      this.responseContains(
        response,
        'The refresh token provided is expired',
        'The requested scope is invalid'
      )
    );
  }
}
