import {Component, Injectable, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogBoxComponent} from '../dialog-box/dialog-box.component';
import {AuthenticationService} from '../_services/authentication/authentication.service';
import {EnvironmentService} from '../_services/environment.service';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JournalConstants} from '../_constants/journal-constants'
import { HostListener } from '@angular/core';
import {UiUtils} from '../_utilities/ui-utils';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';

/** Custom options to configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 0,
  disableTooltipInteractivity: true,
  touchendHideDelay: 0,
};

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }],
    standalone: false
})

@Injectable()
export class OverviewComponent implements OnInit {

  locusID = null;
  hiddenlocusID = '';
  oldID = '';
  previouslyEnteredLocusIdList = JSON.parse(localStorage.getItem("locusIdList"));

  HOUR_1 = "Last 1 Hour";
  HOUR_2 = "Last 2 Hours";
  HOUR_6 = "Last 6 Hours";
  DAY_1 = "Last 1 Day";
  DAY_2 = "Last 2 Days";
  DAY_3 = "Last 3 Days";
  WEEK_1 = "Last 1 Week";
  WEEK_2 = "Last 2 Weeks";
  WEEK_4 = "Last 4 Weeks";
  WEEK_6 = "Last 6 Weeks";
  WEEK_8 = "Last 8 Weeks";
  ALL_TIME = "All Time";

  dateRange = this.DAY_3;
  idType = 'LOCUS_ID';
  lastActive = null;

  isCFFvisible = false;
  trackingID = '';
  beginningTime = '';
  endingTime = '';

  ENVIRONMENT_LIST = [];

  targetEnvironment = null;

  startTimes; // list of timestamps

  sessionID = '';
  sessionLocusID = '';
  sessionStartTime = '';
  sessionEnvironment = '';

  cursorDefault;
  cursorWait;

  NOT_FOUND = 'Not Found';

  clearCache = false;
  // ids of buttons for which we want to toggle the color when cache clearing is enabled
  cacheButtons = ['walkJournal', 'callFlowDiagram'];
  dynamicTip = '';

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog, private route: ActivatedRoute, private authenticationService: AuthenticationService, private environment: EnvironmentService, private backendRequestService: JournalToolBackendRequestsService) {
    this.setVariables();
    if (!window.localStorage.getItem('access_token')) {
      let idBrokerUrl = `https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/authorize?response_type=code&client_id=${this.environment.getClientId()}&scope=spark%3Apeople_read%20Identity%3ASCIM%20journal-tool%3Ajournal_read&state=1&redirect_uri=${encodeURIComponent(this.environment.getRedirectAuthUrl())}`;
      window.open(idBrokerUrl, '_self');
    }
  }

  // enables/disables cache clearing when Ctrl-Delete is typed
  @HostListener('document:keyup.control.delete', ['$event'])
  onKeyup() {
    this.toggleClearCache();
    this.adjustCacheButtons();
  }

  toggleClearCache() {
    this.clearCache = !this.clearCache;
  }

  adjustCacheButtons() {
    this.cacheButtons.forEach((buttonId) => {
      let button = document.getElementById(buttonId);
      if (button !== null) {
        if (this.clearCache === true) {
          button.classList.add('md-button--red');
        } else {
          button.classList.remove('md-button--red');
        }
      }
    });
    this.dynamicTip = (this.clearCache === true ? ' from source data' : '');
  }

  ngOnInit() { this.createCursorStyles(); }

  setVariables() {
    if (this.environment.isEnvControlledByConfig()) {
      this.ENVIRONMENT_LIST = [this.environment.getDomain()];
    } else if (this.environment.getCurrentEvironment() === this.environment.PRODUCTION) {
      this.ENVIRONMENT_LIST = JournalConstants.PROD_ENVS
          .concat(JournalConstants.INT_ENVS)
          .concat(JournalConstants.LOCAL);
    } else if (this.environment.getCurrentEvironment() === this.environment.FEDRAMP) {
      this.ENVIRONMENT_LIST = JournalConstants.FED_ENVS;
    } else {
      this.ENVIRONMENT_LIST = JournalConstants.PROD_ENVS
        .concat(JournalConstants.INT_ENVS)
        .concat(JournalConstants.LOADTEST)
        .concat(JournalConstants.LOCAL);
    }
    this.targetEnvironment = this.route.snapshot.queryParams['environment'] === undefined ? this.ENVIRONMENT_LIST[0] : this.route.snapshot.queryParams['environment'];
    if (this.route.snapshot.queryParams['last_active'] === undefined) {
      this.lastActive = '';
    } else {
      this.setLastActive(this.route.snapshot.queryParams['last_active']);
      let year_month_day = new Date(this.route.snapshot.queryParams['last_active']);
      let cur_date = new Date();
      let dif = Math.ceil((cur_date.getTime() - year_month_day.getTime()) / ((1000 * 60 * 60 * 24 * 7)));
      if (dif > 8) {
        this.setDateRange(this.ALL_TIME);
      } else if (dif > 6) {
        this.setDateRange(this.WEEK_8);
      } else if (dif > 4) {
        this.setDateRange(this.WEEK_6);
      } else if (dif > 1) {
        this.setDateRange(this.WEEK_4);
      } else {
        this.setDateRange(this.WEEK_1);
      }
    }
    this.locusID = this.route.snapshot.queryParams['locus_id'] === undefined ? '' : this.route.snapshot.queryParams['locus_id']; //might not be a locusid, might be meeting number or sipurl
    if (this.lastActive) {//means they were passed in as url parameters so need to store them in localstorage for later
      window.localStorage.setItem('environment', this.targetEnvironment);
      window.localStorage.setItem('locus_id', this.locusID);
      window.localStorage.setItem('last_active', this.lastActive);
    }
    else {
      this.targetEnvironment = window.localStorage.getItem('environment');
      if (!this.targetEnvironment) {
        this.targetEnvironment = this.ENVIRONMENT_LIST[0];
      }
      this.lastActive = window.localStorage.getItem('last_active');
      this.locusID = window.localStorage.getItem('locus_id');

      window.localStorage.removeItem('environment'); //I dont want these values to be automatically populated to previous localstorage values if  they werent passed in as parameters.
      window.localStorage.removeItem('last_active');
      window.localStorage.removeItem('locus_id');
    }
    // make sure all behind-the-scenes variables are set - needed for validations
    this.hiddenlocusID = this.locusID;
    this.oldID = this.locusID;
    if (this.lastActive) {
      this.startTimes = [this.lastActive];
    }
  }

  setEnvironment(e) {
    this.targetEnvironment = e;
  }

  setDateRange(d) {
    this.dateRange = d;
  }

  setidType(idType) {
    this.idType = idType;
    if (this.idType == "SIP_ID") {
      this.idType = "SIP_URI"; //this is the correct string the backend needs
    }
  }

  setLastActive(t) {
    this.lastActive = t;
  }

  setCallFlowFilterVisibility() {
    this.isCFFvisible = !this.isCFFvisible;
  }

  setTrackingID(t) {
    this.trackingID = t;
  }

  setBeginningTime(t) {
    this.beginningTime = t;
  }

  setEndingTime(t) {
    this.endingTime = t;
  }

  // Create cursor styles that we'll use later.  Reusing the same style objects does not
  // increase the size of the document.  Adding an element to a node that is already
  // a child of that node just moves the existing to the end of the list.
  createCursorStyles() {
    // default cursor
    let css = 'button:hover{ cursor: default }';
    this.cursorDefault = document.createElement('style');
    this.cursorDefault.appendChild(document.createTextNode(css));
    // wait cursor
    css = 'button:hover{ cursor: wait }';
    this.cursorWait = document.createElement('style');
    this.cursorWait.appendChild(document.createTextNode(css));
  }

  updateLocusIDLocalStorage() {
    if (!this.previouslyEnteredLocusIdList) {
      this.previouslyEnteredLocusIdList = [this.locusID];
    } else {
      if (this.previouslyEnteredLocusIdList[0] == this.locusID) {
        return;
      }
      let newIDList = [this.locusID];
      // this set lets us filter out duplicates
      let ids = new Set<string>();
      this.previouslyEnteredLocusIdList.forEach((element: string) => {
        if (element != null) {
          element = element.replace(/\s+/g, '');
          if (element !== '' && !ids.has(element)) {
            newIDList.push(element);
            ids.add(element);
          }
        }
      });
      this.previouslyEnteredLocusIdList = newIDList;

      if (this.previouslyEnteredLocusIdList.length > 8) {
        this.previouslyEnteredLocusIdList = this.previouslyEnteredLocusIdList.slice(0, 8);
      }
    }
    localStorage.setItem('locusIdList', JSON.stringify(this.previouslyEnteredLocusIdList));
  }

  setLocusID(id) {
    this.locusID = id;
  }

  async getLocusStatic() {
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] !== true) {
       await UiUtils.openLocusStatic(this.router, this.dialog, this.backendRequestService, this.targetEnvironment, getLocusIdResponse['locusID']);
    }
  }

  // this is the only function that should pass this.locusID to the backend instead of this.getLocusID()
  async getLocusMeetingInfo() {
    UiUtils.setWaitCursor();
    let lidValid = this.validateLocusID();
    if (lidValid['wasError'] !== true ) {
      this.setidType(this.getIdType());
      let locusID = (this.locusID === this.sessionID ? this.sessionLocusID : this.locusID);
      let incomingData = await this.backendRequestService.getLocusMeetingInfo(this.targetEnvironment, locusID, this.idType);
      if (incomingData['status'] === this.backendRequestService.FAILURE) {
        this.dialog.open(DialogBoxComponent, {
          width: '500px',
          data: {message: incomingData['errorMessage']}
        });
      } else if (incomingData['data'] === null) {
        this.dialog.open(DialogBoxComponent, {
          width: '500px',
          data: {message: 'Locus Meeting Info not found'}
        });
      } else {
        let url = this.router.createUrlTree(['/locusMeetingInfo'], {
          queryParams: {
            'locusID': locusID,
            'environment': this.targetEnvironment,
            'idType': this.idType
          }
        })
        let getLocusMeetingInfoWindow = window.open(url.toString(), '_blank');
        getLocusMeetingInfoWindow['data'] = incomingData['data'];
      }
    }
    UiUtils.setDefaultCursor();
  }

  // Check whether lastActive is null, empty, or 'Not Found'.  If so, uses the params to attempt to get
  // session info.  If successful, sets start times and lastActive to the session start time.
  // Otherwise, opens an error dialog.
  // Returns whether lastActive is null, empty, or 'Not Found'.
  private async isLastActiveValid(targetEnvironment, locusID) {
    if (this.lastActive === null || this.lastActive.replace(/\s+/g, '') === '' || this.lastActive === this.NOT_FOUND) {
      if (await this.getLocusSessionInfo(targetEnvironment, locusID)) {
        // this locusID is a session ID (callID), use its start time
        this.startTimes = [this.sessionStartTime];
        this.setLastActive(this.startTimes[0]);
      } else {
        this.dialog.open(DialogBoxComponent, {
          width: '500px',
          data: {message: 'Last Active must be specified'}
        });
      }
    }
    return (this.lastActive !== null && this.lastActive.replace(/\s+/g, '') !== '' && this.lastActive !== this.NOT_FOUND);
  }

  async walkJournal() {
    UiUtils.setWaitCursor();
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] !== true) {
      if (await this.isLastActiveValid(this.targetEnvironment, getLocusIdResponse['locusID'])) {
        let params = {
          'locusID': getLocusIdResponse['locusID'],
          'environment': this.targetEnvironment,
          'date': this.lastActive,
          'trackingID': this.trackingID,
          'beginningTime': this.beginningTime,
          'endingTime': this.endingTime
        };
        if (this.clearCache === true) {
          params['clearCache'] = this.clearCache;
        }
        let url = this.router.createUrlTree(['/walkJournal'], {
          queryParams: params
        });

        window.open(url.toString(), '_blank');
      }
    }
    UiUtils.setDefaultCursor();
  }

  async getJournalRows(unrollRows) {
    UiUtils.setWaitCursor();
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] !== true) {
      if (await this.isLastActiveValid(this.targetEnvironment, getLocusIdResponse['locusID'])) {
        let incomingData = await this.backendRequestService.getJournalRows(this.targetEnvironment, getLocusIdResponse['locusID'], this.lastActive, unrollRows);
        if (incomingData['status'] === this.backendRequestService.FAILURE) {
          this.dialog.open(DialogBoxComponent, {
            width: '500px',
            data: {message: incomingData['errorMessage']}
          });
        } else if (incomingData['data'] === null) {
          this.dialog.open(DialogBoxComponent, {
            width: '500px',
            data: {message: 'Journal Rows not found'}
          });
        } else {
          let url = this.router.createUrlTree(['/journalRows'], {
            queryParams: {
              'locusID': getLocusIdResponse['locusID'],
              'environment': this.targetEnvironment,
              'date': this.lastActive,
              'unrollRows': unrollRows
            }
          });
          let journalRowsWindow = window.open(url.toString(), '_blank');
          journalRowsWindow['data'] = incomingData['data'];
        }
      }
    }
    UiUtils.setDefaultCursor();
  }

  async getCallSummary() {
    UiUtils.setWaitCursor();
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] !== true) {
      if (await this.isLastActiveValid(this.targetEnvironment, getLocusIdResponse['locusID'])) {
        let incomingData = await this.backendRequestService.getCallSummary(this.targetEnvironment, getLocusIdResponse['locusID'], this.lastActive);
        if (incomingData['status'] === this.backendRequestService.FAILURE) {
          this.dialog.open(DialogBoxComponent, {
            width: '500px',
            data: {message: incomingData['errorMessage']}
          });
        } else if (incomingData['data'] === null ||
                   incomingData['data']['callSummaries'] === null ||
                   incomingData['data']['callSummaries'].length === 0) {
          this.dialog.open(DialogBoxComponent, {
            width: '500px',
            data: {message: 'Call Summary not found'}
          });
        } else {
          let url = this.router.createUrlTree(['/callSummary'], {
            queryParams: {
              'locusID': getLocusIdResponse['locusID'],
              'environment': this.targetEnvironment,
              'date': this.lastActive
            }
          });
          let getCallSummaryWindow = window.open(url.toString(), '_blank');
          getCallSummaryWindow['data'] = incomingData['data'];
        }
      }
    }
    UiUtils.setDefaultCursor();
  }

  getIdType() {
    let curIdType = null;
    this.locusID = this.locusID.replace(/\s+/g, '');
    if (this.locusID.indexOf('@') > -1) {
      curIdType = 'SIP_URI';
    } else if (this.locusID.indexOf('-') > -1) {
      curIdType = "LOCUS_ID";
    } else if (/^\d+$/.test(this.locusID)) {// this is a regex to see if all characters are numeric
      curIdType = 'MEETING_ID';
    }
    return curIdType;
  }

  // Makes sure locusID is valid, then makes sure start times and lastActive are in sync with locusID.
  // Returns an object that will always contain a 'wasError' boolean, and if 'wasError' is false, will
  // contain a 'startTimesUpdated' boolean.
  private validateLocusID() {
    if (this.locusID === null || this.getIdType() === null) {
      this.dialog.open(DialogBoxComponent, {
        width: '500px',
        data: {message: (this.locusID === null || this.locusID === '' ? 'ID must be specified' : 'Invalid ID')}
      });
      return {wasError: true};
    }
    if (this.locusID === this.oldID) {
      return {startTimesUpdated: false, wasError: false};
    }
    if (this.locusID === this.sessionID) {
      let updated = false;
      if (this.startTimes === null || this.startTimes.length !== 1 || this.startTimes[0] !== this.sessionStartTime) {
        this.startTimes = [this.sessionStartTime];
        this.setLastActive(this.startTimes[0]);
        updated = true;
      }
      return {startTimesUpdated: updated, wasError: false};
    }
    // if we get here, this locusID value is not associated with any start times we have
    this.startTimes = [];
    this.setLastActive('');
    return {startTimesUpdated: true, wasError: false};
  }

  // function that should get the LocusID in the case that idType isn't already locusID because
  // all of the backend api calls only accept locusIDs and not sip urls. Only get meeting info accepts other types
  // of urls and in the response from there, you can get the other types of ids.
  // returns dict containing locusId and errorStatus
  async getLocusID() {
    let lidValid = this.validateLocusID();
    if (lidValid['wasError'] === true ) {
      return { wasError: true };
    }
    this.setidType(this.getIdType());
    if (this.locusID === this.oldID) {  // if the idType is locusID, we won't ever call getLocusMeetingInfo(), and the error will be shown as whichever button was pressed, but a bad request will be made to that api, perhaps it would be better to call meetinginfo on everything to verify and then the user would also more correctly see a invalid id error instead of something like 'can't open walk journal'.
      return { locusID: this.hiddenlocusID, wasError: false };
    }
    // this.sessionID - sessionID successfully looked up in getLocusSessionInfo()
    // this.sessionLocusID - locusID for session
    // this.sessionStartTime - start time (activeTime) for session
    // this.sessionEnvironment - environment for session
    if (this.idType === 'LOCUS_ID') {
      // check for sessionID
      if (this.locusID === this.sessionID) {
        // this is a session ID and we've already looked it up
        this.hiddenlocusID = this.sessionLocusID;
        this.oldID = this.sessionLocusID;
      } else {
        this.hiddenlocusID = this.locusID;
        this.oldID = this.locusID;
        // clear any session info
        this.sessionID = '';
        this.sessionLocusID = '';
        this.sessionStartTime = '';
        this.sessionEnvironment = '';
      }
      return { locusID: this.locusID, wasError: false };
    }
    let actualLocusID = null;
    let incomingData = await this.backendRequestService.getLocusMeetingInfo(this.targetEnvironment, this.locusID, this.idType);
    if (incomingData['status'] === this.backendRequestService.FAILURE) {
      this.dialog.open(DialogBoxComponent, {
        width: '500px',
        data: { message: incomingData['errorMessage'] }
      });
    } else {
      let str_array = incomingData['data']["locusUrl"].split('/');
      actualLocusID = str_array[str_array.length - 1]// last element is the lid
      this.hiddenlocusID = actualLocusID;
      this.oldID = this.locusID;
    }

    return { locusID: actualLocusID, wasError: !incomingData['status'] };//incoming data status is true if successfull
  }

  // Looks up locusID and startTime for the input ID in the input env.  If found, stores info in instance fields.
  // Opens dialog with error message if the backend call fails.  Not finding session data for the input ID is
  // not considered an error.
  // Returns 1 on success, 0 otherwise.
  async getLocusSessionInfo(targetEnvironment, sessionID) {
    let sessionData = await this.backendRequestService.getLocusSessionInfo(targetEnvironment, sessionID);
    if (sessionData['status'] === this.backendRequestService.SUCCESS && sessionData['data'] != null) {
      this.sessionLocusID = sessionData['data']['locusID'];
      this.sessionID = sessionID;
      this.sessionStartTime = sessionData['data']['startTime'];
      this.sessionEnvironment = targetEnvironment;
      return 1;
    }
    return 0;
  }

  isSessionID(locusID) {
    return (locusID === this.sessionID);
  }

  private async getStartTimesForEnv(targetEnvironment, locusID, dateRange) {
    let startTimes = [];
    let incomingData = await this.backendRequestService.getStartTimes(targetEnvironment, locusID, dateRange);
    if (incomingData['status'] === this.backendRequestService.FAILURE) {
      // wait to show dialog until after unsuccessful session ID check
      if (await this.getLocusSessionInfo(targetEnvironment, locusID)) {
        // this locusID is a session ID (callID), use its start time
        startTimes.push(this.sessionStartTime);
      } else {
        // show dialog with earlier error
        this.dialog.open(DialogBoxComponent, {
          width: '500px',
          data: {message: incomingData['errorMessage']}
        });
      }
    } else {
      if (incomingData['data'].body.startTimes.length > 0) {
        for (let timeStamp in incomingData['data'].body.startTimes) {
          startTimes.push(incomingData['data'].body.startTimes[timeStamp]);
        }
      } else {
        // didn't find anything in the date range.  check if this is a session id
        if (await this.getLocusSessionInfo(targetEnvironment, locusID)) {
          // this locusID is a session ID (callID), use its start time
          startTimes.push(this.sessionStartTime);
        }
      }
    }
    return {'startTimes': startTimes};
  }

  async getStartTimes() {
    UiUtils.setWaitCursor();
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] !== true) {
      this.updateLocusIDLocalStorage();
      let startTimesResponse = await this.getStartTimesForEnv(this.targetEnvironment, getLocusIdResponse['locusID'], this.dateRange);
      if (startTimesResponse['startTimes'].length > 0) {
        this.startTimes = [...startTimesResponse['startTimes']];
      } else {
        this.startTimes = [this.NOT_FOUND];
      }
      this.setLastActive(this.startTimes[0]);
      if (!this.isSessionID(getLocusIdResponse['locusID'])) {
        this.startTimes.push('Live');
      }
    }
    UiUtils.setDefaultCursor();
  }

  async getStartTimesForAllEnvironments() {
    UiUtils.setWaitCursor();
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] === true) {
      UiUtils.setDefaultCursor();
      return; // there was an error getting the locus ID
    }
    this.updateLocusIDLocalStorage();
    this.startTimes = [];
    this.lastActive = '';
    let requests = [];
    let environmentsForRequests = [];
    for (let curEnvironment of this.ENVIRONMENT_LIST) {
      if (curEnvironment === JournalConstants.LOCAL) {
        continue;
      }
      let incomingData = this.getStartTimesForEnv(curEnvironment, getLocusIdResponse['locusID'], this.dateRange);
      requests.push(incomingData);
      environmentsForRequests.push(curEnvironment);
    }
    forkJoin(requests).subscribe((results) => {
      let env = "";
      results.forEach((element, idx) => {
        if (element['startTimes'].length > 0) {
          this.startTimes.push(...element['startTimes']);
          env = environmentsForRequests[idx];
        }
      });
      if (this.startTimes.length === 0) {
        this.startTimes.push(this.NOT_FOUND);
      } else {
        this.targetEnvironment = env;
      }
      this.setLastActive(this.startTimes[0]);
      if (!this.isSessionID(getLocusIdResponse['locusID'])) {
        // may want to use /locus/api/v1/loci/{id} to check FullState.active
        // for now always adding live option as meeting-tools jar does
        this.startTimes.push('Live');
      }
      UiUtils.setDefaultCursor();
    });
  }

  async getCallFlowDiagram() {
    let getLocusIdResponse = await this.getLocusID();
    if (getLocusIdResponse['wasError'] !== true) {
      if (await this.isLastActiveValid(this.targetEnvironment, getLocusIdResponse['locusID'])) {
        await UiUtils.openCallFlowDiagram(this.dialog, this.backendRequestService, this.targetEnvironment,
                                          getLocusIdResponse['locusID'], this.lastActive, this.trackingID, this.beginningTime, this.endingTime,
                                          this.clearCache);
      }
    }
  }

}
