import { Component } from '@angular/core';
import { AuthenticationService } from './_services/authentication/authentication.service';
import { map } from 'rxjs/operators';
import {Observable} from "rxjs";
import {ThemeService} from "./_services/theme.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  public isLoggedIn:Observable<boolean>;
  public showToolbar:boolean = false;
  theme$!: Observable<string>;

  constructor(private authenticationService: AuthenticationService,
              private themeService: ThemeService) {}

  ngOnInit() {
    this.theme$ = this.themeService.getTheme();
    this.isLoggedIn= this.authenticationService.isLoggedIn$.pipe(map((value) => value));
  }

  public showHideToolbar() {
    this.showToolbar = !this.showToolbar;
  }
}
