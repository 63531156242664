import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {EnvironmentService} from '../_services/environment.service';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
    selector: 'app-locus-meeting-info',
    templateUrl: './locus-meeting-info.component.html',
    styleUrls: ['./locus-meeting-info.component.scss'],
    standalone: false
})
export class LocusMeetingInfoComponent implements OnInit {

  data = {};
  jsonView = true;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private environment: EnvironmentService,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    document.title = `Locus Meeting Info : ${this.route.snapshot.queryParams['locusID']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getLocusMeetingInfo(this.route.snapshot.queryParams['environment'], this.route.snapshot.queryParams['locusID'], this.route.snapshot.queryParams['idType']);
      this.data = request['data'];
    } else {
      this.data = window['data'];
    }

    this.jstreeSpinner.hide();
    UiUtils.setDefaultCursor();
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

}

