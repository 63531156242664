import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../_services/authentication/authentication.service';
import { EnvironmentService } from '../_services/environment.service';
import { AppComponent} from "../app.component";
import { ThemeService } from '../_services/theme.service';
import { JournalConstants } from "../_constants/journal-constants";

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    standalone: false
})
export class NavComponent implements OnInit {
  @ViewChild('logoutForm') logoutForm;

  private currentTheme;

  public logoutUrl;
  public token: string;
  public redirectUrl;

  constructor(private authenticationService: AuthenticationService,
              private themeService: ThemeService,
              private environment: EnvironmentService,
              private appComponent: AppComponent) {
    this.logoutUrl = `https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/logout`;
    this.redirectUrl =  this.environment.getRedirectAuthUrl();
  }

  ngOnInit() {
    this.token = window.localStorage.getItem('refresh_token');
    this.currentTheme = this.themeService.getCurrentTheme();
    this.setTheme(this.currentTheme);
  }

  public isLoggedIn(): boolean {
    return !!window.localStorage.getItem('access_token');
  }

  public processLogout() {
    this.authenticationService.logout();
    this.logoutForm.nativeElement.submit();
  }

  public toggleToolbar() {
    this.appComponent.showHideToolbar();
  }

  switchTheme() {
    let oldTheme = this.currentTheme;
    if (this.currentTheme === JournalConstants.JT_LIGHT_THEME) {
      this.currentTheme = JournalConstants.JT_DARK_THEME;
    } else if (this.currentTheme === JournalConstants.JT_DARK_THEME) {
      this.currentTheme = JournalConstants.JT_LIGHT_THEME;
    } else {
      // initial default is light theme
      this.currentTheme = JournalConstants.JT_DARK_THEME;
    }
    document.body.classList.remove(oldTheme)
    this.setTheme(this.currentTheme);
  }

  setTheme(newTheme: string) {
    // the DOM stuff is needed to cover the entire body as opposed to angular content
    document.body.classList.add(newTheme);
    this.themeService.setTheme(newTheme);
  }
}
