import {Component, OnInit} from '@angular/core';

import * as forge from 'node-forge';

@Component({
    selector: 'app-hash-generator',
    templateUrl: './hash-generator.component.html',
    styleUrls: ['./hash-generator.component.scss'],
    standalone: false
})
export class HashGeneratorComponent implements OnInit {
  genFrom = '';
  sha512 = '';

  constructor() {
  }

  ngOnInit() {
  }

  generateHash() {
    if (!this.isClear()) {
      this.sha512 = forge.md.sha512.create().update(this.genFrom).digest().toHex();
    }
  }

  checkKey(event) {
    if (event.key == 'Enter') {
      this.generateHash();
    }
  }

  isClear() {
    if (this.genFrom == '') {
      this.sha512 = '';
      return true;
    }
    return false;
  }
}
