import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {EnvironmentService} from './environment.service';
import { HttpClient } from '@angular/common/http';
import {JournalConstants} from '../_constants/journal-constants';

@Injectable({
  providedIn: 'root'
})
export class JournalToolBackendRequestsService {

  constructor(private router: Router, private environment: EnvironmentService, private http: HttpClient) { }

  public readonly SUCCESS = 1;
  public readonly FAILURE = 0;
  public readonly DONE = 2;
  private LIVE = 'Live';

  private DEVICE_INDEX = 8; // index of the device information in the tableData
  private ATTR_INDEX = 7;

  private liveTimeMap = {}; //stores the (K, V) => (locus ID, last active time) for a live journal

  private getApiURL(targetEnvironment, apiEndpoint, locusID) {
    let baseUrl: string;
    if (targetEnvironment === 'local') {
      baseUrl = this.environment.getDevJournalServiceUrl();
    } else {
      baseUrl = this.environment.getJournalServiceUrl();
    }
    return baseUrl + '/api/v1/' + apiEndpoint + '/' + locusID;
  }

  //returns dict with data, status code and error message
  public async getLocusStatic(targetEnvironment, locusID) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.LOCUS_STATIC_API_ENDPOINT, locusID);
    let errorMessage = 'Unable to get Locus Static';
    let result = { 'status': this.SUCCESS };
    result['data'] = await this.http.get(
      urlToQuery,
      {params: {'environment': targetEnvironment}}
    ).toPromise().catch((error) => {
      result['status'] = this.FAILURE;
      result['errorMessage'] = this.getErrorMessage(errorMessage, error);
    });
    return result;
  }

  public async getBreakoutSummary(targetEnvironment, locusID, _date, bid) {
    const urlToQuery = this.getApiURL(targetEnvironment, 'breakoutSummary', bid);
    let errorMessage = 'Unable to get Breakout Summary';
    let result = { 'status': this.SUCCESS };
    console.log( " utr to query = " + urlToQuery);
    let incomingData = await this.http.get(
      urlToQuery,
      { params: { 'environment': targetEnvironment } }
    ).toPromise().catch((error) => {
      console.log(error.error['message'] + '\n status=: ' + error.status + "\n" + error.message);
      result['status'] = this.FAILURE;
      result['errorMessage'] = this.getErrorMessage(errorMessage, error);
    });
    console.log(" incoming data is =" +incomingData);
    result['data'] = incomingData;
    return result;
  }

  public async getJournalChunk(targetEnvironment, locusID, lastActive, chunkNum, clearCache: boolean = false) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.WALK_JOURNAL_API_ENDPOINT, locusID);
    const errorMessage = 'Cannot open journal';
    let result = { 'status': this.SUCCESS };
    let delaySeconds = 5;
    let counter202 = 0;
    let counter429 = 0;
    //The backend can take time to build responses. Users will see a snapshot of the journal when it was live some amount of time in the past.
    //We want to keep passing in the original time of this request otherwise we will keep asking the backend to kick of a job building a more up-to-date version.
    let isLive: boolean = lastActive === this.LIVE;

    if (isLive) {
      //check to see if a KV pair of locus ID and a timestamp is present
      if (!this.liveTimeMap[locusID]) {
        //if it is not present, then add the KV pair (locus ID, current time)
        this.liveTimeMap[locusID] = new Date().toISOString();
      }

      //pull the last active time from the map with the locus ID as the key
      lastActive = this.liveTimeMap[locusID];
    }

    let response = "";
    while (true) {
      // reset result for local retries
      result['status'] = this.SUCCESS;
      let incomingData = await this.http.get(
        urlToQuery,
        {
          observe: 'response',
          params: {
            'environment': targetEnvironment,
            'date': lastActive,
            'isLive' : isLive.toString(),
            'chunkNumber' : chunkNum.toString(),
            'clearCache' : clearCache.toString()
          }
        }).toPromise().catch((error) => {
        result['status'] = this.FAILURE;
        result['statusCode'] = error.status;
        result['errorMessage'] = this.getErrorMessage(errorMessage, error);
        if (result['errorMessage'].match(/No Transactions Found/s)) {
          result['errorMessage'] = `${errorMessage}: No Transactions Found`;
        }
      }); //makes the call to the walkJournal API

      // make sure we only clear on the first call
      clearCache = false;

      // failure handling
      if (result['status'] !== this.SUCCESS) {
        if (result['statusCode'] === 429) {
          await new Promise(resolve => setTimeout(resolve, 1000 * (delaySeconds + counter429)));
          if (counter429 < 120) {
            counter429 += 5;
          }
          continue;
        } else {
          break;
        }
      }

      if (incomingData['status'] === 202) { //if the response is a 202
        await new Promise(resolve => setTimeout(resolve, 1000 * (delaySeconds + counter202)));
        if (counter202 < 120) {
          counter202 += 5;
        }
      } else if (incomingData['status'] !== 200) { // if the response is anything but 200
        result['status'] = this.FAILURE;
        if (incomingData['status'] === 204) {
          result['errorMessage'] = 'Journal not found';
        } else {
          result['errorMessage'] = errorMessage + ': HTTP status: ' + incomingData['status'] + ', ' + incomingData['statusText'];
        }
        break;
      } else { // the response is a 200
        let doneIndex = incomingData['body'].indexOf("DONE");
        if (doneIndex >= 0) {
          // last chunk from backend
          result['status'] = this.DONE;
          incomingData['body'] = incomingData['body'].substring(0, doneIndex);
        }
        response = incomingData['body'];
        break;
      }
    }

    if (result['status'] !== this.FAILURE) {
      result['data'] = JSON.parse(response);
    }

    response = null;

    return result;
  }

  public async getJournal(environment: string, locusId: string, activeTime: string) {

    let data = '';
    let chunkNum = 0;

    while (true) {
      let request = await this.getJournalChunk(environment, locusId, activeTime, chunkNum);

      if (request['status'] === this.FAILURE) {
        return request;
      }

      // set or combine data
      data = this.combineResponses(data, request['data']);

      // if (data !== null) {} // TODO: invoke per-chunk callback here

      if (data === null && request['status'] !== this.DONE) {
        // no data received, and no DONE indicator
        return { 'status': this.FAILURE, 'errorMessage': 'no data received' };
      }

      if (request['status'] === this.DONE) {
        // received DONE from backend, no more information
        break;
      }

      chunkNum++;
    }

    return { 'status': this.SUCCESS, 'data': data };
  }

  // Request information to display on the journal tool about page
  public async getAboutInfo(targetEnvironment) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.ABOUT_INFO_ENDPOINT, '');
    let errorMessage = JournalConstants.ABOUT_INFO_ERROR_MSG;
    let result = { 'status': this.SUCCESS };

    result['data'] = await this.http.get(
      urlToQuery
    ).toPromise().catch((error) => {
      result['status'] = this.FAILURE;
      result['errorMessage'] = this.getErrorMessage(errorMessage, error);
    });
    return result;
  }

  public async getLocusMeetingInfo(targetEnvironment, locusID, idType) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.MEETING_INFO_API_ENDPOINT, locusID);
    let errorMessage = 'Unable to get Locus Meeting Info';
    let result = { 'status': this.SUCCESS };
    result['data'] = await this.http.get(
      urlToQuery,
      {params: {environment: targetEnvironment, 'idType': idType}}
    ).toPromise().catch((error) => {
      result['status'] = this.FAILURE;
      result['errorMessage'] = this.getErrorMessage(errorMessage, error);
    });
    return result;
  }

  public async getLocusSessionInfo(targetEnvironment, sessionID) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.SESSION_INFO_API_ENDPOINT, sessionID);
    let errorMessage = 'Unable to get Locus Session Info';
    let result = { 'status': this.SUCCESS };
    result['data'] = await this.http.get(
      urlToQuery,
      {params: {environment: targetEnvironment}}
    ).toPromise().catch((error) => {
      result['status'] = this.FAILURE;
      result['errorMessage'] = this.getErrorMessage(errorMessage, error);
    });
    return result;
  }

  public async getJournalRows(targetEnvironment, locusID, lastActive, unrollRows) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.JOURNAL_ROWS_API_ENDPOINT, locusID);
    let errorMessage = 'Cannot open Journal Rows';
    let result = { 'status': this.SUCCESS };
    result['data'] = await this.http.get(
      urlToQuery,
      {
        params: {
          'environment': targetEnvironment,
          'date': lastActive,
          'unrollRows': unrollRows
        }
      })
      .toPromise().catch((error) => {
        result['status'] = this.FAILURE;
        result['errorMessage'] = this.getErrorMessage(errorMessage, error);
      });
    return result;
  }

  public async getCallSummary(targetEnvironment, locusID, lastActive) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.CALL_SUMMARY_API_ENDPOINT, locusID);
    let errorMessage = 'Cannot open Call Summary';
    let result = { 'status': this.SUCCESS };
    result['data'] = await this.http.get(
      urlToQuery,
      {params: {environment: targetEnvironment, date: lastActive}})
      .toPromise().catch((error) => {
        result['status'] = this.FAILURE;
        result['errorMessage'] = this.getErrorMessage(errorMessage, error);
      });
    return result;
  }

  //gets a DTO from a specific locus/rowIndex
  public async getDTO(environment, locusId, date, rowIndex) {
    let baseUrl = this.environment.getJournalServiceUrl();
    if (environment === 'local') {
      baseUrl = this.environment.getDevJournalServiceUrl();
    }
    const urlToQuery = baseUrl + '/api/v1/loci/getDTOs/' + locusId;
    return await this.http.get(
      urlToQuery,
      {
        params: {
          'environment': environment,
          'date': date,
          'rowIndex': String(rowIndex)
        }
      }).toPromise().catch((error) => {
      console.log(this.getErrorMessage('Cannot get DTO', error));
      return {};
    })
  }

  async getStartTimes(targetEnvironment, locusID, dateRange) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.START_TIMES_ENDPOINT, locusID);
    let errorMessage = 'Cannot get start times';
    let result = { 'status': this.SUCCESS };
    result['data'] = await this.http.get(urlToQuery,
                                         {
                                           observe: 'response',
                                           params: {
                                             'environment': targetEnvironment,
                                             'dateRange': dateRange,
                                           },
                                         }).pipe(
      map((data: any) => {
        return data;
      })
    ).toPromise().catch((error) => {
      result['status'] = this.FAILURE;
      result['errorMessage'] = this.getErrorMessage(errorMessage, error);
    });
    return result;
  }

  //returns base64 decoded string containing html for the call flow diagram as the data
  async getCallFlowDiagram(targetEnvironment, locusID, lastActive, trackingID, beginningTime, endingTime, clearCache = false) {
    const urlToQuery = this.getApiURL(targetEnvironment, JournalConstants.CALL_FLOW_ENDPOINT, locusID);
    const genericErrorMsg = 'Cannot get Call Flow diagram';
    const filtersErrorMsg = 'No events were found for the filters';
    let result = { 'status': this.SUCCESS };
    let end = false;
    let delaySeconds = 5;
    let i = 0
    //The backend can take time to build responses. Users will see a snapshot of the journal when it was live some amount of time in the past.
    //We want to keep passing in the original time of this request otherwise we will keep asking the backend to kick of a job building a more up-to-date version.
    let isLive = lastActive === this.LIVE;
    lastActive = isLive ? Date.now() : lastActive;
    while (!end) {
      let incomingData = await this.http.get(
        urlToQuery,
        {
          observe: 'response',
          params: {
            'environment': targetEnvironment,
            'date': lastActive,
            'isLive' : isLive.toString(),
            'tid' : trackingID,
            'begTime' : beginningTime,
            'endTime' : endingTime,
            'clearCache' : clearCache.toString()
          }
        })
        .toPromise().catch((error) => {
          result['status'] = this.FAILURE;
          if (trackingID !== null || beginningTime !== null || endingTime !== null) {
            result['errorMessage'] = this.getErrorMessage(filtersErrorMsg, error);
          } else {
            result['errorMessage'] = this.getErrorMessage(genericErrorMsg, error);
          }
        });

      // make sure we only clear on the first call
      clearCache = false;

      if (incomingData === undefined) {
        return result;
      }

      if (incomingData['status'] === 202) {
        await new Promise(resolve => setTimeout(resolve, 1000 * (delaySeconds + i)));
        if (i < 120) {
          i += 5;
        }
      } else {
        // TODO: find atob() replacement
        result['data'] = atob(incomingData['body']);
        end = true;
      }
    }
    return result;
  }

  private getErrorMessage(baseMsg: String, error: any): String {
    let msg: String = baseMsg;
    if (error !== null) {
      try {
        if (error instanceof Error) {
          msg += ': ' + `${error.name}: ${error.message}`;
        } else if (error instanceof String) {
          msg += ': ' + error;
        } else if (typeof error === 'object' && 'error' in error && 'message' in error['error']) {
          msg += ': ' + `${error.error.message}`;
        } else {
          let consoleMsg = 'unexpected error type: ' + typeof error;
          try {
            consoleMsg += ': ' + JSON.stringify(error);
          } catch (e) {
            console.error('Error in JSON.stringify(): ' + e.toString());
          }
          console.error('cannot extract error message, ' + consoleMsg);
        }
      } catch (e) {
        let consoleMsg = 'error type: ' + typeof error;
        try {
          consoleMsg += ': ' + JSON.stringify(error);
        } catch (e2) {
          console.error('Error in JSON.stringify(): ' + e2.toString());
        }
        console.error('cannot extract error message, ' + consoleMsg + ': ' + e.toString());
      }
    }
    return msg;
  }

  // This method takes in two responses: currentRes - the current data displayed - and newRes which is the data that
  // has to be appended to the current data displayed. It will combine the second response into the first response
  // so that we can use the first response as the data that needs to be displayed in the tool.

  public combineResponses(currentRes, newRes) {
    if (currentRes === null || currentRes === '') {
      return newRes;
    }
    if (newRes === null || newRes === '') {
      return currentRes;
    }
    //COL_NAMES isn't changed
    //PARTICIPANT_COL_NAMES isn't changed
    //'response' field
    //isArchived isn't changed
    this.mergeLists(currentRes['response']['participantInfoList'], newRes['response']['participantInfoList']);
    this.mergeLists(currentRes['response']['deviceInfoList'], newRes['response']['deviceInfoList']);
    this.mergeLists(currentRes['response']['rowList'], newRes['response']['rowList']);
    this.mergeMaps(currentRes['response']['participantDtoListPerTransaction'], newRes['response']['participantDtoListPerTransaction']);
    this.mergeMaps(currentRes['response']['participantDtoMap'], newRes['response']['participantDtoMap']);
    currentRes['response']['rowCountActual'] += newRes['response']['rowCountActual']
    currentRes['response']['testUserNameMap'] = newRes['response']['testUserNameMap'];
    //serviceSubscriberMap isn't changed
    currentRes['response']['participantOffset'] = newRes['response']['participantOffset'];
    //locusStaticResponse isn't changed
    //journalVersion isn't changed
    this.mergeMaps(currentRes['userMap'], newRes['userMap']);
    //serviceUserMap isn't changed
    this.mergeMaps(currentRes['idMap'], newRes['idMap']);
    //deviceNameMap is merged later on
    currentRes['tableData'] = this.combineTableData(currentRes['tableData'], newRes['tableData'], newRes['response']['rowList'], currentRes['userMap'], newRes['deviceNameMap']);
    this.mergeMaps(currentRes['deviceNameMap'], newRes['deviceNameMap']);
    //locusId isn't changed
    //activeTime isn't changed
    return currentRes;
  }

  //"ADD_CALL_LEVEL_TOGGLE" rows are the rows that we can extract call level toggles from. In each row,
  //the updated call level toggles are displayed in the journal - this method takes each
  //"ADD_CALL_LEVEL_TOGGLE" row and returns all the KV pairs so that we can display them later on.
  public extractCallLevelToggles(data) {
    let callLevelToggles = {};
    if (data !== null) {
      let tableData = data['tableData'];
      let counter = 0;
      for (let row of tableData) {
        if (row[this.ATTR_INDEX] === 'CallLevelToggle') {
          //'at' is the attributes of this row
          let at = JSON.parse(data['response']['rowList'][counter]['attrs'])['attrs'];
          for (let j = 0; j < at.length; j++) {
            //here, we store the KV pairs into the variable callLevelToggles
            callLevelToggles[at[j]['key']] = at[j]['val'];
          }
        }
        counter++;
      }
    }

    return callLevelToggles;
  }

  // Merges the new tableData into the current tableData (can use the current tableData for future reference).
  // Also edits the user and device information in the new tableData - in large journals with multiple chunks,
  // it tends to get "lost" (it shows the username as "null"). Therefore, we must edit the tableData so this
  // information is shown in the journal rows in the tool.
  private combineTableData(currData, newData, rowList, userMap, deviceNameMap) {
    const invalidUserName = "null";
    for (let i = 0; i < newData.length; i++) {
      let str = newData[i][this.DEVICE_INDEX];

      //need to edit names such as "null Dev 1".
      //if the username does not have "null", then we can assume that it is a
      //valid username. therefore, we can skip to the next row
      if (str !== null) {
        let nullIndex = str.indexOf(invalidUserName);
        if (nullIndex < 0) {
          continue;
        }

        //retrieve the correct username and add it to the device name
        //str could be something like "null Dev 1" - we want to take out the device name  " Dev 1"
        //so we can add the username to it and update the rowList
        let deviceName = str.substring(invalidUserName.length);

        //each row in the rowList will have a field called "userId" that contains a ~36 character code for each user.
        //another data structure called the userMap maps 36-character codes to usernames
        //like so: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx -> "User Name"
        let userName = userMap[rowList[i]['userId']];

        //here, we combine the username and the device name and update the new tableData
        //we update the tableData because that is what is displayed on the bottom panel in the tool
        newData[i][this.DEVICE_INDEX] = userName + deviceName;

        //the deviceNameMap will also have a username ("starts out as null Dev 1"),
        //so we need to update that as well
        deviceNameMap[rowList[i]['deviceUrl']] = userName + deviceName;
      }
    }

    //the last step is the merge the current tableData and the new tableData
    this.mergeLists(currData, newData);

    //we can return the merged tableData as the first table
    return currData;
  }

  //merge two maps together into the first map
  private mergeMaps(currentList, newList) {
    for (let key in newList) {
      currentList[key] = newList[key];
    }
  }

  //merges elements of the second list at the end of the first list
  private mergeLists(currentList, newList) {
    let i = currentList.length;

    for (let key in newList) {
      let val = newList[key];
      currentList.push(val);
      currentList[i][0] = (i+1).toString();
      i++;
    }
  }

}
