import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';
import {MeetingCltConstants} from './meeting-clt-constants';

@Component({
    selector: 'meeting-clt-component',
    templateUrl: './meeting-clt.component.html',
    styleUrls: ['./meeting-clt.component.scss'],
    standalone: false
})
export class MeetingCltComponent implements OnInit {

  filterGroups = MeetingCltConstants.filterGroups;
  data = {};
  currentData = {};
  jsonView = true;
  currentLabel = this.filterGroups[0].label;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private backendService: JournalToolBackendRequestsService,
              private router: Router,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getJournal(
        this.route.snapshot.queryParams['environment'],
        this.route.snapshot.queryParams['locusID'],
        this.route.snapshot.queryParams['date']);

      if (request['status'] === this.backendService.FAILURE) {
        console.log(request['errorMessage']);
      }

      //extract the CLTs from the journal
      this.data = this.backendService.extractCallLevelToggles(request['data']);

    } else {
      this.data = window['data'];
    }
    this.currentData = this.data;

    this.jstreeSpinner.hide();
    UiUtils.setDefaultCursor();
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

  public showGroup(label, entries) {
    this.currentData = (label === this.filterGroups[0].label) ? this.data : this.retrieveFilteredData(entries);
    this.currentLabel = label;
  }

  public retrieveFilteredData(filterList) {
    let filteredData = {};

    for (let clt in filterList) {
      if (this.data[clt] !== undefined) {
        filteredData[filterList[clt]] = this.data[clt];
      }
    }

    return filteredData;
  }
}
