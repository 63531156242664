import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
    selector: 'app-locus-static-component',
    templateUrl: './locus-static-component.component.html',
    styleUrls: ['./locus-static-component.component.scss'],
    standalone: false
})
export class LocusStaticComponent implements OnInit {

  data = {};
  jsonView = true;
  expandAll = true;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private dialog: MatDialog,
              private backendService: JournalToolBackendRequestsService,
              private router: Router,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    document.title = `Locus Static : ${this.route.snapshot.queryParams['locusID']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getLocusStatic(this.route.snapshot.queryParams['environment'], this.route.snapshot.queryParams['locusID']);
      this.data = request['data'];
    } else {
      this.data = window['data'];
    }

    this.jstreeSpinner.hide();
    UiUtils.setDefaultCursor();
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }
  public toggleExpand() {
    this.expandAll = !this.expandAll;
  }

}
