import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication/authentication.service';
import { AuthInfoService } from '../_services/authInfo.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  public isLoggedIn: boolean;
  private authInfo;
  constructor(
    private authenticationService: AuthenticationService,
    private authInfoService: AuthInfoService,
    private router: Router,
    ) {
      this.authInfoService.authInfoData$.subscribe(data => this.authInfo = data);
    }

  ngOnInit() {
    this.authenticationService.isLoggedIn$.subscribe((res) => {
      this.isLoggedIn = res;
    });
    this.proccessLoginState();
  }

  public login() {
    return this.authenticationService.login();
  }

  public logout() {
    return this.authenticationService.logout();
  }

  /**
   * This method directs the user to CI login
   */
  private proccessLoginState() {
    const code = this.authenticationService.getAuthCode();

    if (this.isLoggedIn) {
      return;
    }

    if (code) {
      this.authenticationService.login();
    } else {
      this.authenticationService.directToCommonIdentityLoginPage();
    }
  }
}
